<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card
          v-if="group.items"
          no-body
          class="card-company-table"
        >
          <b-table
            :items="group.items"
            responsive
            :fields="group.fields"
            class="mb-0"
          >
            <!-- reseller -->
            <template #cell(name)="data">
              <div class="d-flex align-items-center">
                <div>
                  <div class="font-weight-bolder">
                    {{ data.item.title }}
                  </div>
                  <div class="font-small-2 text-muted">
                    {{ data.item.subtitle }}
                  </div>
                </div>
              </div>
            </template>

            <!-- category -->
            <template #cell(category)="data">
              <div class="d-flex align-items-center">
                <b-avatar
                  class="mr-1"
                  variant="light-primary"
                >
                  <feather-icon
                    size="18"
                    :icon="data.item.avatarIcon"
                  />
                </b-avatar>
                <span>{{ data.item.avatarTitle }}</span>
              </div>
            </template>

            <!-- omzet -->
            <template #cell(omzet)="data">
              <div class="d-flex flex-column">
                <span class="font-weight-bolder mb-25">{{ data.item.omzet }}</span>
              </div>
            </template>

            <!-- action -->
            <template #cell(action)="data">
              <div class="d-flex flex-column">
                <feather-icon
                  size="18"
                  icon="EyeIcon"
                  style="cursor: pointer"
                  @click="gotoDetailPerson(data.item.id)"
                />
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol,
  BCard, BTable, BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
  },

  data() {
    return {
      group: {
        items: [
          {
            id: 1,
            no: '#1',
            title: 'Albertus Christian',
            subtitle: 'albertus@the-netwerk.com',
            avatarIcon: 'CoffeeIcon',
            avatarTitle: 'Grocery',
            joinDate: '29 Oct 2018',
            omzet: 'Rp. 42.900.000',
            total_member: '9 users',
          },
          {
            id: 2,
            no: '#2',
            title: 'Bima Indra',
            subtitle: 'bima@the-netwerk.com',
            avatarIcon: 'MonitorIcon',
            avatarTitle: 'Technology',
            joinDate: '10 Oct 2020',
            omzet: 'Rp. 13.200.000',
            total_member: '3 users',
          },
        ],
        fields: [
          { key: 'no', label: 'NO' },
          { key: 'name', label: 'NAME' },
          { key: 'category', label: 'CATEGORY' },
          { key: 'joinDate', label: 'JOIN DATE' },
          { key: 'omzet', label: 'OMZET' },
          { key: 'total_member', label: 'TOTAL MEMBER' },
          { key: 'action', label: '' },
        ],
      },
    }
  },

  methods: {
    gotoDetailPerson(id) {
      this.$router.push({ path: `/leaderboard/group/${id}` })
    },
  },
}
</script>
